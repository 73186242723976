







































/* eslint-disable */
import {getApiUrl} from '@/inc/app.config'
import {useState} from '@u3u/vue-hooks'
import {defineComponent, ref} from '@vue/composition-api'
import Axios from 'axios'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

export default defineComponent({
  name: 'step-nine',
  components: {FeedbackMessage},
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const {user, chrome} = useState(['user', 'chrome'])
    const {i18n, i18nSuivi} = useState('my', ['i18n', 'i18nSuivi'])
    const data = ref({})
    const NINETYONE = ref('91')
    const NINETYTWO = ref('92')
    const NINETYTHREE = ref('93')
    const NINE = ref('9')
    const url = ref()
    const errorFacture = ref(false)
    const feedbackMessage = ref('')

    const download = () => {
      errorFacture.value = false
      Axios.get(
          `${getApiUrl()}/works/documents?NumDossier=${_props.parentData.numDossier[0]}&Type=Facture`
      )
          .then(response => {
            var fileURL = response.data[0].url
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', response.data[0].document)
            document.body.appendChild(fileLink)
            fileLink.click()
            errorFacture.value = false
          })
          .catch(error => {
            errorFacture.value = true
            feedbackMessage.value = error.response.data.message
          })
    }
    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-nine')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })
    const processAction = (text: string) => {
      let html = text.replace(
          '<span style="color: #ff4b00;">télécharger votre facture.</span>',
          ''
      )
      return html
    }

    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      i18nSuivi,
      data,
      NINETYONE,
      NINETYTWO,
      NINETYTHREE,
      NINE,
      url,
      errorFacture,
      download,
      processAction,
      feedbackMessage,
    }
  },
})
